<template>
<div v-if="isLoading">
        <div class="text-center text-danger screen-center" style="padding-top: 20%;">
            <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
            <strong>Cargando...</strong>
        </div>
</div>
    <div v-else>
        <div class="breadcrumb">
            <h1>Nuevo cliente</h1>
        </div>
        <div class="separator-breadcrumb border-top"></div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-sm-12">
                                    <button class="btn th-custom-color" type="submit" @click="storeCliente">Guardar</button>
                                    <router-link :to="{ name: 'cliente-index' }">
                                        <button type="button" class="btn btn-secondary m-1">
                                            Cancelar
                                        </button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <label for="nombre_comercial" class="col-sm-4 col-form-label text-right">Nombre comercial: <span
                                            class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" placeholder="Ingrese el Nombre Comercial" id="nombre_comercial"
                                                v-model="nombreComercial"
                                                required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="razon_social" class="col-sm-4 col-form-label text-right">{{ empresa.texto_razon_social }}
                                            :</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" :placeholder="'Ingrese ' + empresa.texto_razon_social" id="razon_social"
                                                v-model="razonSocial">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="ci_nit"
                                            class="col-sm-4 col-form-label text-right">{{ empresa.texto_numero_contribuyente }}: <span
                                            class="text-danger">*</span></label>
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" :placeholder="'Ingrese ' + empresa.texto_numero_contribuyente" id="ci_nit"
                                                v-model="ciNit"
                                                required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="codigo_cliente" class="col-sm-4 col-form-label text-right">Código de cliente: <span
                                            class="text-danger">*</span></label>
                                        <div class="col-sm-3 mb-4 was-validated">
                                            <input type="text" class="form-control" placeholder="Ingrese el Codigo de Cliente" id="codigo_cliente"
                                                v-model="codigoCliente"
                                                required>
                                            <div class="valid-tooltip codigo-sugerido">Código sugerido.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="fecha_trabajo" class="col-sm-4 col-form-label text-right">Fecha de trabajo: <span
                                            class="text-danger">*</span></label>
                                        <div class="col-sm-3">
                                            <date-picker
                                                id="fecha_trabajo"
                                                v-model="fechaTrabajo"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                width="100%">
                                            </date-picker>
                                        </div>
                                    </div>                                
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <label for="celular_telefono" class="col-sm-4 col-form-label text-right">Teléfono: </label>
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" placeholder="Ingrese el Telefono" id="celular_telefono"
                                            v-model="celularTelefono">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="email" class="col-sm-4 col-form-label text-right">Emails: </label>
                                        <div class="col-sm-6">
                                            <vue-tags-input v-model="tag" :tags="tags" class="tag-custom text-15" @tags-changed="newTags => tags = newTags" placeholder="correo@gmail.com"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="direccion" class="col-sm-4 col-form-label text-right">Dirección: </label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" placeholder="Ingrese la Direccion" id="direccion"
                                                v-model="direccion">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="pais" class="col-sm-4 col-form-label text-right">País: </label>
                                        <div class="col-sm-4">
                                            <multiselect      
                                                v-model="paisSelected"
                                                track-by="id"
                                                label="descripcion"
                                                placeholder="Pais"
                                                :options="paises"
                                                selectLabel="Seleccionar"
                                                @select="getPaises"
                                                :searchable="true">
                                            </multiselect>
                                        </div>
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" placeholder="Ingrese la ciudad"
                                                v-model="ciudad">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="email" class="col-sm-4 col-form-label text-right">Límite de crédito (Bs):</label>
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="0.00"
                                                v-model="limiteCredito">
                                        </div>
                                        <label for="origen" class="col-auto col-form-label text-right">Origen:</label>
                                        <div class="col-sm-4">
                                            <multiselect
                                                v-model="origenCliente"
                                                track-by="id"
                                                label="descripcion"
                                                placeholder="Origen"
                                                :options="origenesClientes"
                                                selectLabel="Seleccionar"
                                                @select ="getCliente"
                                                :searchable="true">
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group row">
                                        <label for="descripcion" class="col-sm-2 col-form-label text-right">Descripción:</label>
                                        <div class="col-sm-10">
                                            <textarea id="descripcion" cols="1" rows="4" v-model="descripcion"
                                                placeholder="Ingrese una Descripcion"
                                                class="form-control no-resize"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'

import VueTagsInput from '@johmun/vue-tags-input';


export default {
    name: 'create',
    data() {
       return {};
    },
    created() {
        this.getData();
        this.getCli();
    },
    methods: {
        ...call('cliente/formularioCliente', [
            'store', 
            'getPaises', 
            'getCliente'
            ]),
        async getData() {
            const CREATE_OK = await this.getPaises();
            if(CREATE_OK) {
                this.isLoading = false;
            }
        },
        async getCli() {
            const CREATE_OK = await this.getCliente();
            if(CREATE_OK) {
                this.isLoading = false;
            }
        },
        async storeCliente(){
            this.isLoading = true;
            const STORE_OK = await  this.store();
            if (STORE_OK){
                this.$router.push({name: 'cliente-index'});
            }
            this.isLoading =  false;
        },
    },
    computed: {
        ...get('cliente/formularioCliente', [
            'vendedores',
            'tipoClientes',
            'paises',
            'origenesClientes',
        ]),
        ...sync('cliente/formularioCliente', [
            'nombreComercial',
            'empresa',
            'codigoCliente',
            'fechaTrabajo',
            'vendedor',
            'tipoClienteSelected',
            'celularTelefono',
            'tag',
            'tags',
            'direccion',
            'paisSelected',
            'ciudad',
            'limiteCredito',
            'origenCliente',
            'descripcion',
            'isLoading',
            'razonSocial',
            'ciNit',
        ]),
    },
    watch: {},
    components: {
        DatePicker,
        Multiselect,
        VueTagsInput,
       
        
    },
    beforeDestroy() {
        this.$store.dispatch('cliente/formularioCliente/resetFormularioCliente');
    }
}
</script>